<script>
	import Layout from "../../layouts/main"
	import PageHeader from "@/components/page-header"
	import appConfig from "@/app.config"

	export default {
		middleware: "authentication",
		page: {
			title: "",
			meta: [
				{
					name: "description",
					content: appConfig.description,
				},
			],
		},
		components: {
			Layout,
			PageHeader,
		},
		data: function() {
			return {
				items: [
					{
						text: "View",
					},
					{
						text: "Attendance",
						active: true,
					},
				],
				excelDownloading: false,
				isReportTableBusy: false,
				totalRows: 1,
				currentPage: 1,
				perPage: 10,
				pageOptions: [10, 25, 50, 100, 500],
				filter: null,
				filterOn: [],
				sortBy: "createdAt",
				sortDesc: false,
				moment: this.$moment,
				fields: [
					{
						key: "actions",
						label: 'Action',
						sortable: true,
						tdClass: "align-center align-middle",
						thStyle: { backgroundColor: '#f6f6f6' }
					},
					{
						key: 'name',
						label: "Client Name",
						sortable: true,
						tdClass: "align-center align-middle",
						thStyle: { backgroundColor: '#f6f6f6' }
					},
					{
						key: 'corporateEmployeeCount',
						label: "Employee Count",
						sortable: true,
						tdClass: "align-center align-middle",
						thStyle: { backgroundColor: '#f6f6f6' }
					},
					{
						key: "corporateBranchCount",
						label: 'Branch Count',
						sortable: true,
						tdClass: "align-center align-middle",
						thStyle: { backgroundColor: '#f6f6f6' }
					},
					{
						key: 'createdAt',
						label: "Date Of Registration",
						sortable: true,
						tdClass: "align-center align-middle",
						thStyle: { backgroundColor: '#f6f6f6' }
					},
				],
				tableData: [],
			}
		},
		computed: {
			/**
			 * Total no. of records
			 */
			rows() {
				return this.totalRows;
			},
		},
		mounted: function() {
			this.pageSize = 10
			this.totalRows = this.tableData.length
			this.getClientLists()
			//this.checkS3Bucket()
		},
		methods: {
			
			getClientLists: function() {
				this.isReportTableBusy = true
				this.tableData = []
				this.axios.post('super-admin/corporate-list', {
					page: this.currentPage,
					pageSize: this.perPage,
				}).then((result) => {
					this.isReportTableBusy = false
					this.tableData = result.data.data.listing
					this.totalRows = result.data.data.totalCounts
				}).catch((error) => {
					console.log(error)
				})
			},
			onFiltered: function(filteredItems) {
				// Trigger pagination to update the number of buttons/pages due to filtering
				this.totalRows = filteredItems.length;
				this.currentPage = 1;
			},
			changePageSize: function(value) {
				this.pageSize = value
				this.getClientLists()
			},
			changePage: function(value) {
				this.currentPage = value
				this.getClientLists()
			},
			viewDashboard: function(data) {
				window.addEventListener('storage', (e) => {
					console.log(e)
				})
				localStorage.setItem('isDashboardView', true)
				localStorage.setItem('corporateId', data._id)
				const routerData = this.$router.resolve({
					name: 'corporate-lists',
					params: {
						corporateId: data._id
					},
				})
				window.open(routerData.href)
			}
		},
	}
</script>
<style scoped></style>
<template>
	<Layout>
		<PageHeader :items="items" />
		<div class="row">
			<div class="col-12">
				<div class="card">
					<div class="card-body">
						<div class="row mt-4">
							<div class="col-sm-12 col-md-4" style="margin-bottom:15px;">
								<!-- <div role="group" class="btn-group">
									<button type="button"
										class="btn btn-outline-primary" @click="downloadReport()">
										<span>Download Report</span>
										<b-spinner v-if="excelDownloading" small type="grow"></b-spinner>
										<b v-if="excelDownloading">Loading...</b>
									</button>
								</div> -->
							</div>
							<div class="col-sm-12 col-md-4">
								<div id="tickets-table_length" class="dataTables_length">
									<label class="d-inline-flex align-items-center">
										<span>Show</span>
										<b-form-select
											style="margin-left:5px; margin-right:5px"
											v-model="perPage"
											size="sm"
											:options="pageOptions"
											@change="changePageSize" />
										<span>entries</span>
									</label>
								</div>
							</div>
							<!-- Search -->
							<div class="col-sm-12 col-md-4 row">
								<div id="tickets-table_filter" class="dataTables_filter text-md-end">
									<label class="d-inline-flex align-items-center">
										<span>Search:</span>
										<b-form-input v-model="filter" type="search" placeholder="Search..." class="form-control form-control-sm ms-2"></b-form-input>
									</label>
								</div>
							</div>
							<!-- End search -->
						</div>
						<div class="table-responsive mb-0">
							<b-table id="clients-list" striped hover outlined bordered :items="tableData" :fields="fields"
								thead-class="bg-transparent" responsive="sm" :per-page="0"
								:current-page="currentPage" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc"
								:filter="filter" :filter-included-fields="filterOn" @filtered="onFiltered"
								fixed-header :busy="isReportTableBusy">
								<template #table-busy>
									<div class="text-center text-danger my-2">
										<b-spinner variant="primary" class="align-middle" type="grow"></b-spinner>
										<strong class="text-primary">Loading...</strong>
									</div>
								</template>
								<template v-slot:cell(actions)="{ item }">
									<button class="btn btn-outline-primary" @click="viewDashboard(item)">View Dashboard</button>
									<!-- <router-link :to="{
										name: 'attendance',
										params: {
											corporateId: item._id
										},
									}" class="btn btn-outline-primary" target="_blank">View Dashboard</router-link> -->
								</template>
								<template v-slot:cell(createdAt)="{ item }">
									<span>{{ moment(item.createdAt).format('MMMM Do, YYYY') }}</span>
								</template>
							</b-table>
						</div>
						<div class="row">
							<div class="col">
								<div class="dataTables_paginate paging_simple_numbers float-end">
									<ul class="pagination pagination-rounded mb-0">
										<!-- pagination -->
										<b-pagination
											aria-controls="clients-list"
											v-model="currentPage"
											:total-rows="rows"
											:per-page="perPage"
											@change="changePage" />
									</ul>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</Layout>
</template>